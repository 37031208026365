export {default as LogoP} from '../images/logo.png';
export {default as snkCozumel} from '../images/snorkelCozumel.png';
export {default as Satisfaction} from '../images/satisfaccion.png';
export {default as Idioma} from '../images/lenguaje.png';
export {default as Duration} from '../images/duracion.png';
export {default as CZM1} from '../images/gallery/czm/czm-snorkel-1.webp';
export {default as CZM2} from '../images/gallery/czm/czm-snorkel-2.webp';
export {default as CZM3} from '../images/gallery/czm/czm-snorkel-3.webp';
export {default as CZM4} from '../images/gallery/czm/czm-snorkel-4.webp';
export {default as CZM5} from '../images/gallery/czm/czm-snorkel-5.webp';
export {default as CZM6} from '../images/gallery/czm/czm-snorkel-6.webp';
export {default as CZM7} from '../images/gallery/czm/czm-snorkel-7.webp';
export {default as CZM8} from '../images/gallery/czm/czm-snorkel-8.webp';
export {default as CZM9} from '../images/gallery/czm/czm-snorkel-9.webp';
export {default as CZM10} from '../images/gallery/czm/czm-snorkel-10.webp';
export {default as CZM11} from '../images/gallery/czm/czm-snorkel-11.webp';
export {default as CZM12} from '../images/gallery/czm/czm-snorkel-12.webp';
export {default as Point} from '../images/icons/point.png';
export {default as Phone} from '../images/icons/telefono.png';
export {default as Email} from '../images/icons/correo.png';
export {default as snkCZM} from '../images/snorkelCozumel.png';
export {default as Snorkel1} from '../images/gallery/czm/snorkel-1.webp';
export {default as Snorkel2} from '../images/gallery/czm/snorkel-2.webp';
export {default as Snorkel3} from '../images/gallery/czm/snorkel-3.webp';
export {default as Snorkel4} from '../images/gallery/czm/snorkel-4.webp';
export {default as Snorkel5} from '../images/gallery/czm/snorkel-5.webp';
export {default as Safe} from '../images/SafeTravels.png';
export {default as Specs} from '../images/iconos-tour-specs.png';
export {default as Itinerary} from '../images/icono-itinerary.png';
export {default as Meeting} from '../images/icon-meeting-point.png';
export {default as Facebook} from '../images/icon-facebook.png';
export {default as Instagram} from '../images/icon-instagram.png';
export {default as Youtube} from '../images/icon-youtube.png';
export {default as Address} from '../images/icon-adress.png';
export {default as PHone} from '../images/icon-phone.png';
export {default as Mail} from '../images/icon-mail.png';
export {default as Nachi} from '../images/nachi.png';
export {default as PYM} from '../images/pym.png';
export {default as Sedetur} from '../images/sedetur.png';
