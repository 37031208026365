/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import React from "react"
import PropTypes from "prop-types"
// import { useStaticQuery, graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"
import Header from "./header"
import "./layout.css"
import { Facebook, Instagram, Youtube, Address, Mail, PHone, PYM, Nachi } from "../images"
import { graphql,useStaticQuery } from "gatsby"
// import {Row,Col } from 'antd';
import Img from "gatsby-image";
const Layout = ({ children }) => {
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `)
  const data = useStaticQuery(graphql`
  query {
   bgFooter: file(relativePath: { eq: "bgfooter.png" }) {
      childImageSharp {
        fluid(maxWidth: 1800, quality: 80) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    },
  }
`)
  return (
    <>
      <Header/>
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 1200,
          padding: `0 1.0875rem 0`,
        }}
      >
        <main>{children}</main>
      </div>
      <div className="flex LogoCompany">
        {/*<img src={Safe} alt=""/>*/}
        <div className="bg-blue"><img src={PYM} alt=""/></div>
        <div className="contTxtPartner">
          <p>in</p>
          <p>partnership</p>
          <p>with</p>
        </div>
        <div className="bg-green"><img src={Nachi} alt=""/></div>
      </div>
      <footer className="bgFooter">
        {/*<Img fluid={data.bgFooter.childImageSharp.fluid} alt=" Cozumel Reef" />*/}
        {/*<img src={bgFooter} alt=""/>*/}
        <div className="contFooter">
          <div className="flex center">
              <div className="contFooterInfo">
                <div className="textLeft flex"><img src={Address} alt=""/> <div className="contP"><p>Costera Sur Km. 15 Hotel Zone Sur</p> <p> Zip Code 77600</p> <p>Cozumel,  Quintana Roo, Mexico</p></div> </div>
              </div>

              <div className="contFooterInfo">
                <p className="textRight flex"><img src={Mail} alt="" className="img-MailFooter"/> reservations@playamia.com</p>
                <p className="textRight flex"><img src={PHone} alt=""/> 52-987-872-9040</p>
              </div>
          </div>
          <div className="valley txt-follow">Follow us!</div>
          <div className="D-flex center social-icons">
            <img src={Facebook} alt=""/>
            <img src={Instagram} alt=""/>
            <img src={Youtube} alt=""/>
          </div>
          © {/*{new Date().getFullYear()}*/} 2020, Cozumel Snorkel
          {/*{` `}*/}
          <a href="https://www.playamia.com"> by Playa Mia</a>
        </div>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default injectIntl(Layout)