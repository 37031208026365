import React , { useEffect,useState }from "react";
import { MenuOutlined, RightOutlined, CloseCircleFilled } from '@ant-design/icons';
import { Menu} from "antd";
import 'antd/dist/antd.css';
import { IntlContextConsumer, changeLocale, useIntl } from "gatsby-plugin-intl"
import { Helmet } from "react-helmet";
import { LogoP } from "../images"
import Img from "gatsby-image";
import { graphql,useStaticQuery } from "gatsby"


const Header = () => {
  const intl = useIntl();
  const [openMenu, isOpenMenu] = useState(false);
  const data = useStaticQuery(graphql`
  query {
    banner: file(relativePath: { eq: "banner.webp" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 80) {
           ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    bannerM: file(relativePath: { eq: "banner-resp.webp" }) {
      childImageSharp {
        fluid(maxWidth: 999, quality: 80) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
   logoP: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fixed(width: 185,height:61, quality: 80) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
  }
`)
  let lang=intl.locale==='en'?'es':'en';
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  });
   let handleScroll = () => {
    if (window.scrollY > 150) {
      document.querySelector(".ant-layout-header").className = "ant-layout-header menuScroll";
    } else {
      document.querySelector(".ant-layout-header").className = "ant-layout-header";
    }
  }
  let menuScroll = (id) => {
    document.getElementById(id).scrollIntoView();
  }
  let locales=['en','es'];
 return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{intl.locale==='en'? 'Cozumel Snorkel by Catamaran & Beach!' :'Cozumel Snorkeling Tour en Catamarán y Playa!'}</title>
        <link rel="canonical" href="https://www.cozumelreefsnorkeling.com/" />
        {locales.map((locale) => {
          return <link rel="alternate" href={`https://www.cozumelreefsnorkeling.com/${locale}`} hrefLang={locale} key={locale}/>
        })}
        <script type="application/ld+json">{`
      [
      [{
  "@context": "https://schema.org",
  "@type": "TouristTrip",
  "name": "Cozumel Snorkel by Catamaran & Beach!",
  "description": "This trip is modeled as two distinct Tourist Trips using the subTrip property.",
  "subTrip": [
      {
        "@type": "TouristTrip",
        "name": "Snorkeling in El Cielo bay",
        "description": "Receive snorkeling instruction and gear and then follow your PADI certified guide for drift snorkeling over famous Colombia Reef. You will discover an infinite array of colors and forms of the exuberant marine life. Then owe at “El Cielo” (The Sky) named after the incredible blue and starfish."
      },
      {
        "@type": "TouristTrip",
        "name": "Cozumel Snorkel by Catamaran & Beach",
        "description": "Afterward, enjoy, before the crystal-clear ocean, of a tasty lunch including burgers, hotdogs, quesadillas, fresh guacamole, crunchy tortilla chips, sodas, and cold beer."
      }
    ]
},
{
  "@context": "https://schema.org",
  "@type": "Product",
  "url" : "https://www.cozumelreefsnorkeling.com/",
  "description": "Board a two-hull motorsailer for a scenic cruise along the coastline as you make your way to Cozumel's southernmost reefs, right into the heart of the National Marine Park; the ultimate snorkeling experience.",
  "name": "Cozumel Snorkel by Catamaran & Beach!",
  "sku":"CGDP001",
  "image": "https://www.tourfinder.com/images/gallery/snorkel/img-snorkel-4.jpg",
    "brand" : {
    "@type" : "Brand",
    "logo" : "playamia.com/images/grand-beach-water-park-logo.svg"
  },
  "offers": {
    "@type": "Offer",
    "availability": "https://schema.org/InStock",
    "price": "99.00",
    "priceCurrency": "USD"
  }
}
]
      ]
    `}</script>
      </Helmet>
      <header className="ant-layout-header" style={{position: 'fixed',width:'100%',alignItems: 'center',justifyContent: 'center', display: 'inline-flex'}}>
        <div className="Dflex" style={{
          margin: `0 auto`,
          maxWidth: 1200,
          padding: `0 1.0875rem 0`,
          zIndex: 1,
          marginLeft: 'auto',
          marginRight: 'auto',
          left: 0,
          right: 0,
        }}>

            {/*<Img fixed={data.logoP.childImageSharp.fixed} alt="playa mia beach break"/>*/}
            <img className="logoSnorkel" src={LogoP}/>

          <IntlContextConsumer>
            {({}) =>
              <>
             <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['2']} className="MenuDesktop">
             <Menu.Item onClick={() => menuScroll('home')} key="2">{intl.formatMessage({ id: "menu.home"})}</Menu.Item>
             <Menu.Item onClick={() => menuScroll('gallery')} key="3">{intl.formatMessage({ id: "menu.gallery"})}</Menu.Item>
            <Menu.Item onClick={() => menuScroll('contact')} key="4">{intl.formatMessage({ id: "menu.contact_us"})}</Menu.Item>
            <Menu.Item key="5"  onClick={() => changeLocale(lang)}>{intl.formatMessage({ id: "menu.lang"})}</Menu.Item>
            </Menu>
                <div onClick={() => isOpenMenu(!openMenu)} className="MenuResponsivo">
                  <MenuOutlined />
                </div>
                  <div style={{display:openMenu? "" : "none"}}  className="menuItems">
                    <div onClick={() => isOpenMenu(!openMenu)} className="menuClose">
                      <CloseCircleFilled className="closeIcon"/>
                    </div>
                    <ul>
                      <li>
                        <div className="">
                          <div className="nameMenu" onClick={() => menuScroll('home')} key="2"><div className="contItemMenu">{intl.formatMessage({ id: "menu.home"})}</div> <span><RightOutlined /></span></div>
                        </div>
                      </li>
                      <li>
                        <div className="">
                          <div className="nameMenu" onClick={() => menuScroll('gallery')} key="3"><div className="contItemMenu" style={{color:'#31a8e0'}}>{intl.formatMessage({ id: "menu.gallery"})}</div> <span style={{color:'#31a8e0'}}><RightOutlined /></span></div>
                        </div>
                      </li>
                      <li>
                        <div className="">
                          <div className="nameMenu" onClick={() => menuScroll('contact')} key="4"><div className="contItemMenu" style={{color:'#7eddd8'}}>{intl.formatMessage({ id: "menu.contact_us"})}</div> <span style={{color:'#7eddd8'}}><RightOutlined /></span></div>
                        </div>
                      </li>
                      <li>
                        <div className="">
                          <div className="nameMenu" key="5"  onClick={() => changeLocale(lang)}><div className="contItemMenu" style={{color:'#fa7c92'}}>{intl.formatMessage({ id: "menu.lang"})}</div><span style={{color:'#fa7c92'}}><RightOutlined /></span></div>
                        </div>
                      </li>
                    </ul>
                  </div>

            </>
            }

          </IntlContextConsumer>

        </div>

      </header>

      <div style={{
        margin: `0 auto`,
        maxWidth: 1200,
        padding: `0 1.0875rem 0`,
      }}>
        <div  className="tituloBanner">
          <h1>{intl.formatMessage({ id: "menu.h1_menu"})}</h1>
          <h2>{intl.formatMessage({ id: "menu.h2_menu"})}</h2>
          <h1>{intl.formatMessage({ id: "menu.h1_menux"})}</h1>
        </div>
      </div>
      <div id={'home'} className="bannerSNK">
        <Img className="banner" fluid={data.banner.childImageSharp.fluid} alt=" Cozumel Snorkel" />
        <Img className="bannerResponsivo" fluid={data.bannerM.childImageSharp.fluid} alt=" Cozumel Snorkel"/>
      </div>
    </>
  )
}

export default Header
